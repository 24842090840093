html,
body,


a,
a:visited {
  color: black;
}

.notification h1,
.notification h2,
.notification h3 {
  font-weight: bold;
}

#root {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main {
  background-color: #fff;
}

footer {
  text-align: center;
}
